<template>
  <div>
    <NavigationBar showBackBtn titel="Erweiterungskatalog"></NavigationBar>
    <section>
      <template>
        <v-container>
          <Subheader
            icon="mdi-toy-brick"
            title="Verfügbare Erweiterungen"
          ></Subheader>
          <div class="mt-7">
            <v-row class="match-height">
              <v-col v-for="(item, id) in extensions" :key="id" cols="12">
                <v-card class="mx-auto" :disabled="item.disabled">
                  <v-card-title>
                    <v-avatar :color="`${item.color} lighten-5`" rounded>
                      <v-icon :color="item.color">
                        {{ item.icon }}
                      </v-icon>
                    </v-avatar>
                    <span class="ml-4"> {{ item.name }} </span>
                    <v-spacer></v-spacer>
                    <v-btn v-if="item.isComingSoon" disabled depressed text>
                      Nicht verfügbar
                    </v-btn>
                    <v-btn v-if="extensionIsActive(item.id)" disabled text>
                      <v-icon left>mdi-check</v-icon>
                      Aktiv
                    </v-btn>
                    <v-btn
                      v-if="
                        !item.isRequired &&
                        !item.isComingSoon &&
                        !extensionIsActive(item.id)
                      "
                      :disabled="item.disabled"
                      color="success"
                      depressed
                      @click="toggleConfirmationDialog(item)"
                    >
                      <v-icon left>mdi-toy-brick-plus</v-icon>
                      Aktivieren
                    </v-btn>
                  </v-card-title>
                  <v-divider v-if="item.children"></v-divider>
                  <v-card-text v-if="item.children">
                    <!-- <p>{{ item.description }}</p> -->
                    <p class="mb-1">
                      Diese Erweiterung besteht aus folgenden Modulen:
                    </p>
                    <v-chip
                      v-for="(child, id) in item.children"
                      :key="id"
                      label
                      small
                      class="mr-2 mt-2"
                    >
                      <v-icon
                        v-if="
                          extensionIsActive(item.id) &&
                          !extensionIsActive(child.id)
                        "
                        left
                        small
                        >mdi-close-circle</v-icon
                      >
                      {{ child.name }}
                    </v-chip>
                  </v-card-text>
                  <!-- <v-card-text>
                  <v-container fluid>
                    <v-switch
                      v-for="(child, id) in item.children"
                      :key="id"
                      v-model="moduleIDs"
                      color="primary"
                      inset
                      :label="child.name"
                      :value="child.id"
                      class="my-n1"
                    ></v-switch>
                  </v-container>
                </v-card-text> -->
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </template>
    </section>
    <section>
      <CustomDialog
        v-if="helpers.confirmationDialog.model"
        @close="toggleConfirmationDialog({})"
        title="Erweiterung aktivieren"
        text="Bist Du sicher, dass Du diese Erweiterung aktivieren möchtest?"
        :loading="helpers.confirmationDialog.loading"
        :btnAction="enableExtension"
        :errorMessage="helpers.confirmationDialog.errorMessage"
        btnColor="success"
        width="s"
      >
        <template v-slot:content>
          <v-card outlined>
            <v-card-title>
              <v-avatar :color="`${selectedExtension.color} lighten-5`" rounded>
                <v-icon :color="selectedExtension.color">
                  {{ selectedExtension.icon }}
                </v-icon>
              </v-avatar>
              <span class="ml-4"> {{ selectedExtension.name }} </span>
            </v-card-title>
            <v-card-text v-if="selectedExtension.children.length > 0">
              <p class="mb-1">
                Diese Erweiterung besteht aus folgenden Modulen:
              </p>
              <v-chip
                v-for="(child, id) in selectedExtension.children"
                :key="id"
                label
                small
                class="mr-2 mt-2"
              >
                {{ child.name }}
              </v-chip>
              <p class="mt-3">
                Es entstehen keine weiteren Kosten durch das Aktivieren.
              </p>
            </v-card-text>
          </v-card>
        </template></CustomDialog
      >
      <v-snackbar
        v-model="helpers.snackbar.isVisible"
        class="pb-4"
        bottom
        app
        timeout="3000"
        ><v-icon color="success" class="mr-1">mdi-check-circle</v-icon>
        {{ helpers.snackbar.message }}
      </v-snackbar>
    </section>
  </div>
</template>

<script>
import { functions } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { modules } from "@/data/app-modules.js";

export default {
  name: "extensions-shop",
  components: {
    NavigationBar,
    Subheader,
    CustomDialog,
  },
  data() {
    return {
      extensions: modules,
      selectedExtension: null,
      helpers: {
        confirmationDialog: {
          model: false,
          loading: false,
          errorMessage: "",
        },
        snackbar: {
          isVisible: false,
          message: "",
        },
      },
    };
  },
  computed: {
    selectedExtensionIds() {
      if (this.selectedExtension === null) {
        return [];
      } else {
        let result = this.selectedExtension.children.map(
          (children) => children.id
        );
        result.push(this.selectedExtension.id);
        return result;
      }
    },
  },
  methods: {
    toggleConfirmationDialog(selectedExtension) {
      this.selectedExtension = selectedExtension;
      this.helpers.confirmationDialog.model =
        !this.helpers.confirmationDialog.model;
      this.helpers.confirmationDialog.errorMessage = "";
    },
    extensionIsActive(extensionId) {
      let activeExtensionIds =
        this.$store.state.organization.activeOrganization.config.extensions
          .activated;
      if (activeExtensionIds.includes(extensionId)) {
        return true;
      } else {
        return false;
      }
    },
    enableExtension() {
      this.helpers.confirmationDialog.loading = true;
      const enableExtension = functions.httpsCallable("shop-enableExtensions");
      enableExtension({
        organizationId: this.$route.params.organizationId,
        extensionIds: this.selectedExtensionIds,
      })
        .then((result) => {
          console.log(result);
          if (result.data.success) {
            this.helpers.confirmationDialog.model = false;
            this.helpers.confirmationDialog.loading = false;
            this.helpers.snackbar.message = "Erweiterung erfolgreich aktiviert";
            this.helpers.snackbar.isVisible = true;
          }
        })
        .catch((error) => {
          // handle error
          console.error(error);
          console.error(error.message);
          this.helpers.confirmationDialog.errorMessage = error.message;
          this.helpers.confirmationDialog.loading = false;
        });
    },
  },
};
</script>
