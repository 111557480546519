var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavigationBar',{attrs:{"showBackBtn":"","titel":"Erweiterungskatalog"}}),_c('section',[[_c('v-container',[_c('Subheader',{attrs:{"icon":"mdi-toy-brick","title":"Verfügbare Erweiterungen"}}),_c('div',{staticClass:"mt-7"},[_c('v-row',{staticClass:"match-height"},_vm._l((_vm.extensions),function(item,id){return _c('v-col',{key:id,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"disabled":item.disabled}},[_c('v-card-title',[_c('v-avatar',{attrs:{"color":`${item.color} lighten-5`,"rounded":""}},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('span',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-spacer'),(item.isComingSoon)?_c('v-btn',{attrs:{"disabled":"","depressed":"","text":""}},[_vm._v(" Nicht verfügbar ")]):_vm._e(),(_vm.extensionIsActive(item.id))?_c('v-btn',{attrs:{"disabled":"","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Aktiv ")],1):_vm._e(),(
                      !item.isRequired &&
                      !item.isComingSoon &&
                      !_vm.extensionIsActive(item.id)
                    )?_c('v-btn',{attrs:{"disabled":item.disabled,"color":"success","depressed":""},on:{"click":function($event){return _vm.toggleConfirmationDialog(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-toy-brick-plus")]),_vm._v(" Aktivieren ")],1):_vm._e()],1),(item.children)?_c('v-divider'):_vm._e(),(item.children)?_c('v-card-text',[_c('p',{staticClass:"mb-1"},[_vm._v(" Diese Erweiterung besteht aus folgenden Modulen: ")]),_vm._l((item.children),function(child,id){return _c('v-chip',{key:id,staticClass:"mr-2 mt-2",attrs:{"label":"","small":""}},[(
                        _vm.extensionIsActive(item.id) &&
                        !_vm.extensionIsActive(child.id)
                      )?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-close-circle")]):_vm._e(),_vm._v(" "+_vm._s(child.name)+" ")],1)})],2):_vm._e()],1)],1)}),1)],1)],1)]],2),_c('section',[(_vm.helpers.confirmationDialog.model)?_c('CustomDialog',{attrs:{"title":"Erweiterung aktivieren","text":"Bist Du sicher, dass Du diese Erweiterung aktivieren möchtest?","loading":_vm.helpers.confirmationDialog.loading,"btnAction":_vm.enableExtension,"errorMessage":_vm.helpers.confirmationDialog.errorMessage,"btnColor":"success","width":"s"},on:{"close":function($event){return _vm.toggleConfirmationDialog({})}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-avatar',{attrs:{"color":`${_vm.selectedExtension.color} lighten-5`,"rounded":""}},[_c('v-icon',{attrs:{"color":_vm.selectedExtension.color}},[_vm._v(" "+_vm._s(_vm.selectedExtension.icon)+" ")])],1),_c('span',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.selectedExtension.name)+" ")])],1),(_vm.selectedExtension.children.length > 0)?_c('v-card-text',[_c('p',{staticClass:"mb-1"},[_vm._v(" Diese Erweiterung besteht aus folgenden Modulen: ")]),_vm._l((_vm.selectedExtension.children),function(child,id){return _c('v-chip',{key:id,staticClass:"mr-2 mt-2",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(child.name)+" ")])}),_c('p',{staticClass:"mt-3"},[_vm._v(" Es entstehen keine weiteren Kosten durch das Aktivieren. ")])],2):_vm._e()],1)]},proxy:true}],null,false,695443174)}):_vm._e(),_c('v-snackbar',{staticClass:"pb-4",attrs:{"bottom":"","app":"","timeout":"3000"},model:{value:(_vm.helpers.snackbar.isVisible),callback:function ($$v) {_vm.$set(_vm.helpers.snackbar, "isVisible", $$v)},expression:"helpers.snackbar.isVisible"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]),_vm._v(" "+_vm._s(_vm.helpers.snackbar.message)+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }