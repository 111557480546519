<template>
  <div>
    <NavigationBar titel="Erweiterungen" :actions="actions"></NavigationBar>
    <section>
      <TippCard
        hintID="Admin_Erweiterungen_1_V1"
        text="Mithilfe von Erweiterungen können Sie den Funktionsumfang von Mira One ganz auf die Bedürfnisse deiner Organisation anpassen. Gerne untersützen wir Sie bei der Auswahl und Konfiguration für Ihre Organisation."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-toy-brick"
                title="Aktive Erweiterungen"
              ></Subheader>
              <div class="mt-7">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="(extension, index) in activeExtensions"
                      :key="index"
                    >
                      <v-expansion-panel-header>
                        <v-row no-gutters>
                          <v-col cols="8">
                            <v-avatar
                              :color="`${extension.color} lighten-5`"
                              rounded
                            >
                              <v-icon :color="extension.color">
                                {{ extension.icon }}
                              </v-icon>
                            </v-avatar>
                            <span class="ml-4 text-h6">
                              {{ extension.name }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <!-- <p>{{ extension.description }}</p> -->
                        <p class="mb-1" v-if="extension.children">
                          Diese Erweiterung besteht aus folgenden Modulen:
                        </p>
                        <div>
                          <v-chip
                            v-for="(module, id) in extension.children"
                            :key="id"
                            label
                            small
                            class="mr-2 mt-2"
                          >
                            {{ module.name }}
                          </v-chip>
                          <!-- <p class="mt-3">
                            Es entstehen keine weiteren Kosten durch die
                          Verwendenung dieses Moduls.
                        </p> -->
                        </div>
                        <v-btn
                          color="error"
                          depressed
                          :disabled="extension.isRequired"
                          @click="disableModule(extension)"
                          class="mt-4"
                        >
                          <v-icon left @click="disableModule(extension)"
                            >mdi-toy-brick-remove</v-icon
                          >
                          Deaktivieren und alle Daten löschen
                        </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <!-- <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12" >
              <Subheader
                icon="mdi-toy-brick-remove"
                title="Deaktivierte Erweiterungen mit vorhandenen Datensätzen"
              ></Subheader>
              <div class="mt-7">
                
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section> -->
    <!-- 
       TODO:
      (1) Tabelle: Aktive Erweiterungen (mit Knopf Deaktivieren, Daten löschen)
      (2) Btn: Erweiterung hinzufügen/aktivieren
      (3) Katalog/Liste/Übersicht: Erweiterungen (Alle, Details, Aktivieren)
     -->
  </div>
</template>
<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import { ADMINISTRATION_EXTENSIONS_manage } from "@/data/permission-types.js";
import { modules } from "@/data/app-modules.js";

export default {
  name: "admin-extensions",
  components: {
    NavigationBar,
    Subheader,
    TippCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Modul aktivieren",
          permissions: `${ADMINISTRATION_EXTENSIONS_manage}`,
          icon: "mdi-toy-brick-plus",
          actionStyle: "textBtn",
          function: this.openShop,
        },
      ],
      sortBy: "",
      extensions: modules,
    };
  },
  computed: {
    activeExtensions() {
      var activeExtensionIds =
        this.$store.state.organization.activeOrganization.config.extensions
          .activated;
      if (!activeExtensionIds.length) {
        activeExtensionIds = [];
      }
      var activeExtensions = [];
      activeExtensionIds.forEach((item) => {
        activeExtensions.push({ id: item });
      });
      // const result = activeExtensions.map((r) => {
      //   const u = this.extensions.find((u) => u.id === r.id);
      //   return { ...r, ...u };
      // });
      // return result;
      return this.extensions
        .map((page) => ({
          ...page,
          children: page.children
            ? // when children is truthy
              page.children.filter(
                // filter out those not in `activatedModules`
                (child) => activeExtensionIds.includes(child.id)
              )
            : page.children,
        }))
        .filter(
          // only keep page if in `activatedModules` and...
          (page) =>
            activeExtensionIds.includes(page.id) &&
            // if children is truthy and has length or...
            (page.children?.length ||
              // if children is falsy
              !page.children)
        );
    },
  },
  created() {},
  methods: {
    openShop() {
      this.$router.push({ name: "admin-extensions-shop" });
    },
    disableModule(extension) {
      alert(
        "Kontaktieren Sie den Support, um ein Modul zu deaktivieren.\n\nGeben Sie dabei folgende Daten an:\n\nOrganisations-ID: " +
          this.$route.params.organizationId +
          "\nID des zu deaktivierenden Moduls: " +
          extension.id
      );
    },
  },
};
</script>
